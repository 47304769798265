import {
  sym,
  type EventListenerName,
} from './types.js'

import EventListenerRegistry from './EventListenerRegistry.js'
import NamedEventListenerBuilder from './NamedEventListenerBuilder.js'

export default class EventListenerBuilder extends NamedEventListenerBuilder<symbol> {
  constructor() {
    super(new EventListenerRegistry())
  }

  name<N extends EventListenerName>(name: N) {
    return new NamedEventListenerBuilder(this[sym], name)
  }

  remove(name: EventListenerName) {
    return this[sym].remove(name)
  }

  removeAll() {
    return this[sym].removeAll()
  }
}