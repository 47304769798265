export const sym = Symbol('EventListenerRegistry')

export type EventListener<T> = (event: T) => any
export type EventListenerNode = Window | Document | HTMLElement
export type EventListenerName = string | symbol
export type EventListenerOnceOptions = { capture?: boolean, passive?: boolean }
export type EventListenerOptions = EventListenerOnceOptions & { once?: boolean, signal?: AbortSignal }

export type EventListenerEntry<N extends EventListenerName> = {
  name: N
  remove: () => void
}

export type CustomEventName = `dx:${string}`
export type CustomEventMap = {
  [K in CustomEventName]: CustomEvent
}

export type EventListenerMap<N extends EventListenerNode> = CustomEventMap & (
  N extends Document ? DocumentEventMap :
  N extends Window ? WindowEventMap :
  HTMLElementEventMap
)
