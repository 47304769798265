import EventListenerBuilder from './EventListenerBuilder.js'

/**
 * Returns an API to manage event listeners within React components.
 * 
 * Event listeners may be named with `named(name)` and later removed with `remove(name)`.
 * If an event listener is given a name, and then later another event listener is given the same name,
 * the original event listener will be removed before the new event listener is scheduled.
 * 
 * All event listeners are automatically removed when the component unmounts.
 */
export default function useEventListeners() {
  const api = useMemo(() => new EventListenerBuilder(), [])
  useEffect(() => () => api.removeAll(), [api])
  return api
}