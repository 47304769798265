import {
  sym,
  type EventListener,
  type EventListenerMap,
  type EventListenerName,
  type EventListenerNode,
  type EventListenerOnceOptions,
  type EventListenerOptions,
} from './types.js'

import type EventListenerRegistry from './EventListenerRegistry.js'


export default class NamedEventListenerBuilder<N extends EventListenerName> {
  #name?: N
  [sym]: EventListenerRegistry

  constructor(registry: EventListenerRegistry, name?: N) {
    this.#name = name
    this[sym] = registry
  }

  /**
   * Attaches an event listener to `node` that invokes `fn` when events of `type`
   * are dispatched.
   *
   * @param node The DOM node to attach a listener to.
   * @param type The name of the event to listen for.
   * @param fn The function to invoke when events of `type` are dispatched.
   * @param options The options object.
   * @param options.capture A boolean indicating whether events of `type` are
   *   dispatched to this listener before being dispatched to any beneath it in the DOM tree.
   * @param options.passive A boolean indicating that `fn` will never call `preventDefault()`.
   *   This drastically improves performance for some types of events, including `scroll`.
   * @param options.once A boolean indicating that `fn` should be invoked at most
   *   once after being added. If `true`, the listener will be automatically removed when invoked.
   * @return A function that may be invoked to remove the event listener.
   */
  // add<T extends keyof WindowEventMap>(node: Window, type: T, fn: EventListener<WindowEventMap[T]>, options?: EventListenerOptions): EventListenerRemoveFn
  // add<T extends keyof DocumentEventMap>(node: Document, type: T, fn: EventListener<DocumentEventMap[T]>, options?: EventListenerOptions): EventListenerRemoveFn
  // add<T extends keyof HTMLElementEventMap>(node: HTMLElement, type: T, fn: EventListener<HTMLElementEventMap[T]>, options?: EventListenerOptions): EventListenerRemoveFn
  add<E extends EventListenerNode, T extends keyof EventListenerMap<E>>(node: E, type: T, fn: EventListener<EventListenerMap<E>[T]>, options: EventListenerOptions = Object._) {
    let listener = fn
    let remove: null | (() => void) = null

    if (options.once) {
      listener = (event: EventListenerMap<E>[T]) => {
        if (remove) {
          remove()
          remove = null
          return fn(event)
        }
      }
    }

    remove = () => node.removeEventListener(type as string, listener as any, Object.omit(options, ['once', 'signal']))
    node.addEventListener(type as string, listener as any, options)

    const removeAndUntrack = this[sym].track(remove, this.#name)

    if (options.signal) {
      options.signal.addEventListener('abort', removeAndUntrack)
    }

    return removeAndUntrack
  }

  /**
   * Attaches an event listener to `node` that invokes `fn` when events of `type`
   * are dispatched. After the first invocation of `fn` the event listener is removed.
   *
   * @param node The DOM node to attach a listener to.
   * @param type The name of the event to listen for.
   * @param fn The function to invoke when events of `type` are dispatched.
   * @param options The options object.
   * @param options.capture A boolean indicating whether events of `type` are
   *   dispatched to this listener before being dispatched to any beneath it in the DOM tree.
   * @param options.passive A boolean indicating that `fn` will never call `preventDefault()`.
   *   This drastically improves performance for some types of events, including `scroll`.
   * @return A function that may be invoked to remove the event listener.
   */
  once<E extends EventListenerNode, T extends keyof EventListenerMap<E>>(node: E, type: T, fn: EventListener<EventListenerMap<E>[T]>, options: EventListenerOnceOptions = Object._) {
    return this.add(node, type, fn, { ...options, once: true })
  }
}