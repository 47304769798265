import type {
  EventListenerEntry,
  EventListenerName,
} from './types.js'


export default class EventListenerRegistry {
  #listeners: Map<EventListenerName, EventListenerEntry<EventListenerName>>

  constructor() {
    this.#listeners = new Map()
  }

  track(remove: () => void, name: EventListenerName = Symbol('listener')) {
    this.remove(name)

    const listener: EventListenerEntry<EventListenerName> = {
      name,
      remove,
    }

    this.#listeners.set(name, listener)
    return () => this.#remove(listener)
  }

  remove(name: EventListenerName) {
    const listener = this.#listeners.get(name)

    if (listener) {
      this.#remove(listener)
    }
  }

  removeAll() {
    this.#listeners.forEach(listener => listener.remove())
    this.#listeners = new Map()
  }

  #remove(listener: EventListenerEntry<EventListenerName>) {
    listener.remove()
    this.#listeners.delete(listener.name)
  }
}